// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
// Bypasses TS6133. Allow declared but unused functions.
// @ts-ignore
function id(d: any[]): any { return d[0]; }
 
  const createTag = (data) => ({ type: "TAG", symbol: data[0], name: data[1].join('') });
  const createAnd = (data) => {
      let parts = [ data[0] ]
      if (data[2].type === 'AND')
          parts.push(... data[2].parts)
      else 
          parts.push(data[2])
      return {
          type: "AND",
          parts: parts
      };    
  };
  const createOr = (data) => {
      let parts = [ data[0] ]
      if (data[4].type === 'OR')
          parts.push(... data[4].parts)
      else 
          parts.push(data[4])
      return {
          type: "OR",
          parts: parts
      }
  };
  const createHierarchy = (data) => 
    ({ type: "HIERARCHY", parent: data[0], child: data[4], data });
  const createDeepHierarchy = (data) => 
    ({ type: "DEEP_HIERARCHY", parent: data[0], child: data[4], data });
 const single = data => {
     if (data.length !== 1) throw new Error("data not single: "+JSON.stringify(data))
    return data[0]
 }

interface NearleyToken {
  value: any;
  [key: string]: any;
};

interface NearleyLexer {
  reset: (chunk: string, info: any) => void;
  next: () => NearleyToken | undefined;
  save: () => any;
  formatError: (token: never) => string;
  has: (tokenType: string) => boolean;
};

interface NearleyRule {
  name: string;
  symbols: NearleySymbol[];
  postprocess?: (d: any[], loc?: number, reject?: {}) => any;
};

type NearleySymbol = string | { literal: any } | { test: (token: any) => boolean };

interface Grammar {
  Lexer: NearleyLexer | undefined;
  ParserRules: NearleyRule[];
  ParserStart: string;
};

const grammar: Grammar = {
  Lexer: undefined,
  ParserRules: [
    {"name": "Main", "symbols": ["Query"], "postprocess": single},
    {"name": "Query", "symbols": ["Expression"], "postprocess": single},
    {"name": "Expression$string$1", "symbols": [{"literal":"O"}, {"literal":"R"}], "postprocess": (d) => d.join('')},
    {"name": "Expression", "symbols": ["Term", "WhiteSpace", "Expression$string$1", "WhiteSpace", "Expression"], "postprocess": createOr},
    {"name": "Expression", "symbols": ["Term"], "postprocess": single},
    {"name": "Term$string$1", "symbols": [{"literal":"A"}, {"literal":"N"}, {"literal":"D"}], "postprocess": (d) => d.join('')},
    {"name": "Term", "symbols": ["Factor", "WhiteSpace", "Term$string$1", "WhiteSpace", "Term"], "postprocess": createAnd},
    {"name": "Term", "symbols": ["Factor"], "postprocess": single},
    {"name": "Factor", "symbols": ["Hierarchy"], "postprocess": single},
    {"name": "Factor", "symbols": ["GroupOrTags"], "postprocess": single},
    {"name": "GroupOrTags", "symbols": ["Group"], "postprocess": single},
    {"name": "GroupOrTags", "symbols": ["TagList"], "postprocess": single},
    {"name": "Group", "symbols": [{"literal":"("}, "WhiteSpace", "Expression", "WhiteSpace", {"literal":")"}], "postprocess": d => d[2]},
    {"name": "Hierarchy", "symbols": ["GroupOrTags", "WhiteSpace", {"literal":">"}, "WhiteSpace", "GroupOrTags"], "postprocess": createHierarchy},
    {"name": "Hierarchy$string$1", "symbols": [{"literal":">"}, {"literal":">"}], "postprocess": (d) => d.join('')},
    {"name": "Hierarchy", "symbols": ["GroupOrTags", "WhiteSpace", "Hierarchy$string$1", "WhiteSpace", "GroupOrTags"], "postprocess": createDeepHierarchy},
    {"name": "TagList", "symbols": ["Tag", "WhiteSpace", "TagList"], "postprocess": createAnd},
    {"name": "TagList", "symbols": ["Tag"], "postprocess": single},
    {"name": "Tag$ebnf$1", "symbols": [/[a-zA-Z0-9_:/-]/]},
    {"name": "Tag$ebnf$1", "symbols": ["Tag$ebnf$1", /[a-zA-Z0-9_:/-]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "Tag", "symbols": [/[#\+@]/, "Tag$ebnf$1"], "postprocess": createTag},
    {"name": "WhiteSpace", "symbols": [/[ \n\t]/], "postprocess": d => undefined},
    {"name": "_$ebnf$1", "symbols": []},
    {"name": "_$ebnf$1", "symbols": ["_$ebnf$1", "WhiteSpace"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "_", "symbols": ["_$ebnf$1"], "postprocess": d => undefined}
  ],
  ParserStart: "Main",
};

export default grammar;
