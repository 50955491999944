import './ui/shoelace'

import { flow } from '@wovin/core/mobx'
import { Logger } from 'besonders-logger'
import { CID } from 'multiformats'
import { createSignal } from 'solid-js'

// import { useAgent } from './data/agent/AgentState'
import { initUseAgent } from './data/lazy-agent'

import { initializeCryptoKeypairs } from './data/agent/AgentCrypto'
import { getApplogDB } from './data/ApplogDB'

import { StoragePermsErr } from './components/mini-components'
import { getBlockStore } from './ipfs/blockStore'
import { initKuboClient } from './ipfs/kubo'
import { initStorage } from './ipfs/storage'
import { startSyncService } from './ipfs/sync-service'
import { initPlugins } from './plugins/init'
import { useAppSettings } from './ui/app-settings'
import { useCurrentThread, withDS } from './ui/reactive'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.DEBUG, { prefix: '[init]' }) // eslint-disable-line no-unused-vars

// GLOBALS SIGNALS
export const [initialized, setInitialized] = createSignal(false)
export const [storageError, setStorageError] = createSignal(null)

export const appInit = flow(function*({ /* setInitialized, */ pubFromUrl }) {
	const thread = getApplogDB()
	const currentDS = withDS(thread, () => useCurrentThread())
	DEBUG(`appInit start`, { pubFromUrl, currentDS, thread: thread.nameAndSizeUntracked })
	const agent = (yield initUseAgent())()
	DEBUG(`[appInit]`, { agent })
	yield initializeCryptoKeypairs(agent)
	DEBUG(`appInit storage`)
	const appSettings = withDS(thread, () => useAppSettings())
	initKuboClient()
	yield withDS(thread, () => initStorage())

	// yield initIPNS()

	yield agent.loadIDB()

	yield initPlugins()

	// const blocks = untracked(()=>query(currentDSc))
	// const roots = withDS(rawDS, () => useRoots())

	// if (!roots.length) {

	// window.addEventListener('hashchange', onHashChange)
	// 	https://web.dev/articles/persistent-storage#check_if_your_sites_storage_has_been_marked_as_persistent
	if (navigator.storage && navigator.storage.persist) {
		const wasPersisted = yield navigator.storage.persisted()
		let persistGranted = yield navigator.storage.persist()
		DEBUG(`Persisted storage status:`, { isPersisted: persistGranted, wasPersisted })

		if (!persistGranted) {
			WARN(`Persisted storage status:`, { isPersisted: persistGranted, wasPersisted })
			setStorageError(StoragePermsErr)
		}
	}
	LOG(`appInit Starting services`)
	startSyncService(true)

	const blockStore = getBlockStore()
	yield blockStore.open()
	// @ts-expect-error window.
	window.blockStore = blockStore
	// @ts-expect-error window.
	window.CID = CID
	// void startHelia()
	LOG(`appInit end`)
	return () => {/* dispose */} // window.removeEventListener('hashchange', onHashChange)
})
// const TestPage = lazy(() => import('./TestPage'))
